import { jsx, jsxs } from 'react/jsx-runtime';
import { GlobalNotification } from '@kaizen/components';
import { matchIdFromUrl, isSuperuserBrowsablePage } from '../helpers/superuserNav.mjs';
import { useQuery } from '../../hooks/index.mjs';
import cx from 'classnames';
const LinkItem = ({
  className,
  children
}) => {
  return jsx("li", {
    className: cx(className, "un-ml-24 un-mr-0 rtl:un-mr-24 rtl:un-ml-0"),
    children: children
  });
};
const SuperuserNav = ({
  isSuperuser,
  accountId: userAccountId,
  accessedAccountId,
  accessedAccountName
}) => {
  const accessedAccountAggregateId = accessedAccountId || matchIdFromUrl();
  const {
    data: superuserData,
    isLoading,
    isFetching
  } = useQuery("superuserNav/[accountId]", {
    params: {
      accountId: `${accessedAccountAggregateId}`
    },
    disableAuthRedirect: true,
    disableQueryPreload: true,
    enabled: !(accessedAccountId && accessedAccountName) && !!accessedAccountAggregateId && isSuperuserBrowsablePage(isSuperuser)
  }); // remove this type assertion when migrating back to frontend-apis
  const name = accessedAccountName !== null && accessedAccountName !== void 0 ? accessedAccountName : superuserData === null || superuserData === void 0 ? void 0 : superuserData.name;
  const accountDataExists = !!userAccountId && !!accessedAccountAggregateId;
  const isBrowsingOtherAccount = userAccountId !== accessedAccountAggregateId;
  const shouldShowSuperUserNav = isSuperuserBrowsablePage(isSuperuser) && accountDataExists && isBrowsingOtherAccount;
  if (!shouldShowSuperUserNav || isLoading && isFetching) return null;
  return jsx("div", {
    className: "un-relative un-z-[1029]",
    children: jsx(GlobalNotification, {
      type: "cautionary",
      children: jsxs("ul", {
        className: "un-flex un-items-center un-justify-center",
        children: [jsx(LinkItem, {
          className: "un-list-none",
          children: jsx("b", {
            children: name
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/app/programs/${accessedAccountAggregateId}`,
            children: "Programs"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/account/${accessedAccountAggregateId}/survey_index`,
            children: "Surveys"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/app/cycles-plan/${accessedAccountAggregateId}`,
            children: "Performance cycles"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/account/${accessedAccountAggregateId}/users`,
            children: "Users"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/account/${accessedAccountAggregateId}/settings`,
            children: "Settings"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/app/roles/accounts/${accessedAccountAggregateId}`,
            children: "Roles and permissions"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/features/${accessedAccountAggregateId}`,
            children: "Feature flags"
          })
        }), jsx(LinkItem, {
          children: jsx("a", {
            href: `/app/product-modules/accounts/${accessedAccountAggregateId}/flags`,
            children: "Product modules"
          })
        })]
      })
    })
  });
};
export { SuperuserNav as default };
